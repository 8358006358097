import BaseForm from '@/shared/models/BaseForm';
class VenueFacilitiesModel extends BaseForm {
    id;
    mandatoryFacilities;
    onDemandFacilities;
    presentFacilities;
    constructor() {
        super();
        this.id = '';
        this.mandatoryFacilities = [];
        this.presentFacilities = [];
        this.onDemandFacilities = [];
    }
    apiAttributes() {
        return {
            id: 'id',
            mandatoryFacilities: 'mandatory-facilities',
            presentFacilities: 'present-facilities'
        };
    }
}
export default VenueFacilitiesModel;
