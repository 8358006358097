import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api';
import { cloneDeep, isEmpty } from 'lodash';
import FormServiceItem from '@/shared/components/form/FormServiceItem.vue';
import PriceInput from '@/uikit/PriceInput.vue';
import InfoBlock from '@/shared/components/InfoBlock.vue';
import FormVerticalRadio from '@/shared/components/form/FormVerticalRadio.vue';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import VenueFacilitiesForm from '@/pages/vendor/venue/facilities/forms/VenueFacilitiesForm';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { VAT_RATES } from '@/shared/const';
import { debounce, servicesInOperationOrder } from '@/util/utils';
import getServices from '@/api/venue/getServices';
import toJSON from '@/api/base/toJSON';
import saveServices from '@/api/venue/patchServices';
import addServices from '@/api/venue/postServices';
import ServiceModel from '@/shared/models/common/ServiceModel';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import Popover from '@/components/Popover.vue';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import EventBus from '@/shared/services/eventBus';
import { SpaceOrderItemType } from '@/generated-types/graphql.types';
export default defineComponent({
    components: {
        FormSelect,
        FormServiceItem,
        FormVerticalRadio,
        InfoBlock,
        Option,
        Popover,
        PriceInput,
        SaveFooter,
        Toggle
    },
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(_, context) {
        const { root, emit } = context;
        const form = ref(new VenueFacilitiesForm());
        const loadedForm = ref({});
        const errors = ref([]);
        const isSaving = ref(false);
        const isInformingVenueUpdated = ref(false);
        const spaceDetails = computed(() => globalThis.$store.getters['$_vendor/spaceDetails']);
        const venueFacilities = computed(() => globalThis.$store.getters['$_vendor/venueFacilities']);
        const venueServices = computed(() => globalThis.$store.getters['$_vendor/venueServices']);
        const vatRates = computed(() => {
            return [
                {
                    label: root.$i18n.t('onboarding.venue.facilities.select_option_vat_21'),
                    value: VAT_RATES.TWENTY_ONE
                },
                {
                    label: root.$t('onboarding.venue.facilities.select_option_vat_9'),
                    value: VAT_RATES.NINE
                },
                {
                    label: root.$t('onboarding.venue.facilities.select_option_vat_none'),
                    value: VAT_RATES.NONE
                }
            ];
        });
        const isSettingsChanged = computed(() => {
            if (!isEmpty(loadedForm.value)) {
                const _form = {
                    ...form.value,
                    presentFacilities: form.value.presentFacilities === undefined
                        ? undefined
                        : form.value.presentFacilities.filter(item => item.selected),
                    onDemandFacilities: form.value.onDemandFacilities === undefined
                        ? undefined
                        : form.value.onDemandFacilities.filter(item => item.selected)
                };
                const _loadedForm = {
                    ...loadedForm.value,
                    presentFacilities: loadedForm.value.presentFacilities === undefined
                        ? undefined
                        : loadedForm.value.presentFacilities.filter(item => item.selected),
                    onDemandFacilities: loadedForm.value.onDemandFacilities === undefined
                        ? undefined
                        : loadedForm.value.onDemandFacilities.filter(item => item.selected)
                };
                return JSON.stringify(_form) !== JSON.stringify(_loadedForm);
            }
            return false;
        });
        watch(() => venueFacilities.value, () => {
            if (!venueServices.value.facilities) {
                form.value.loadFromModel(venueFacilities.value);
            }
            else {
                form.value.loadFromModel(venueServices.value.facilities);
            }
        }, { immediate: true });
        const saveDataToLocalStorage = () => {
            if (!root.$route.params.venueId) {
                globalThis.$store.commit('$_vendor/SET_VENUE_SERVICES', {
                    model: 'facilities',
                    data: { ...form.value, completed: true }
                });
            }
            emit('settingsChanged', true);
        };
        watch(() => form.value, () => {
            const services = [
                ...form.value.onDemandFacilities,
                ...form.value.presentFacilities
            ];
            services.forEach((service) => {
                if (service.maxInventory > 0) {
                    service.selectedLimitOption = 1;
                }
            });
            const isServiceChecked = services.some(e => e.selected);
            if (isServiceChecked)
                debounce(saveDataToLocalStorage(), 500);
        }, { deep: true, immediate: true });
        const updateFacilities = (services) => {
            function updateService(arr) {
                return arr.map(f => {
                    const item = services.find(i => i.system_service_id === f.id);
                    if (item) {
                        const model = new ServiceModel();
                        model.loadFromAPI(item);
                        model.selected = true;
                        return model;
                    }
                    return f;
                });
            }
            form.value.presentFacilities = updateService(form.value.presentFacilities);
            form.value.onDemandFacilities = updateService(form.value.onDemandFacilities);
        };
        const loadFacilities = async () => {
            const services = await toJSON(getServices(root.$route.params.venueId));
            updateFacilities(services);
        };
        const hasChanges = () => {
            return root.$route.params.venueId && isSettingsChanged.value;
        };
        const isChangesSaved = () => {
            return !hasChanges() && isInformingVenueUpdated.value;
        };
        const startInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = true;
        };
        const cancelInformingVenueUpdated = () => {
            isInformingVenueUpdated.value = false;
        };
        const onRevert = () => {
            form.value = cloneDeep(loadedForm.value);
        };
        const checkErrors = () => {
            errors.value = [];
            // Service VAT rate validation
            const selectedServices = form.value.onDemandFacilities.filter(service => service.selected);
            selectedServices.forEach(el => {
                if (el.vat === null && el.price && el.price > 0) {
                    errors.value.push(`vatRate-${el.id}`);
                }
            });
            if (errors.value.length > 0) {
                root.$scrollTo(`#${errors.value[0]}`, 1500, { offset: -150 });
            }
            return errors.value;
        };
        const getVatError = facility => {
            if (errors.value.includes(`vatRate-${facility.id}`)) {
                return root.$t('onboarding.organisation_details.errors.vat_rate_empty');
            }
        };
        const saveChanges = async () => {
            function generateServiceItems(arr) {
                return arr
                    .filter(s => s.selected)
                    .map(s => (s.price ? s : { ...s, price: 0 }))
                    .map(s => new ServiceModel(s).buildSendData());
            }
            const newFacilities = [
                ...form.value.onDemandFacilities,
                ...form.value.presentFacilities
            ];
            const oldFacilities = [
                ...loadedForm.value.onDemandFacilities,
                ...loadedForm.value.presentFacilities
            ];
            const newFacilitiesData = generateServiceItems(newFacilities);
            const oldFacilitiesData = generateServiceItems(oldFacilities);
            const { addedServices, changedServices, removedIds } = servicesInOperationOrder(oldFacilitiesData, newFacilitiesData);
            const requestBody = {
                venue_services: changedServices,
                venue_services_to_delete_ids: removedIds
            };
            await saveServices({ body: JSON.stringify(requestBody) });
            if (addedServices.length) {
                const requestBody = {
                    venue_id: root.$route.params.venueId,
                    venue_services: addedServices
                };
                const response = await addServices({
                    body: JSON.stringify(requestBody)
                });
                if (response.status === 201) {
                    const services = await response.json();
                    updateFacilities(services);
                }
            }
        };
        const onSaveChanges = () => {
            checkErrors();
            if (errors.value.length > 0)
                return;
            saveChanges()
                .then(() => {
                loadedForm.value = cloneDeep(form.value);
                startInformingVenueUpdated();
            })
                .catch(cancelInformingVenueUpdated);
        };
        const mainAction = async () => {
            checkErrors();
            if (errors.value.length > 0)
                return;
            globalThis.$store.commit('$_vendor/SET_VENUE_SERVICES', {
                model: 'facilities',
                data: { ...form.value, completed: true }
            });
            EventBus.$emit('updateWizardStep');
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.CATERING,
                params: { venueId: root.$route.params.venueId }
            });
        };
        const updateFacilityLimit = (facility, val) => {
            facility.inventoryEnabled = val === 1;
        };
        onMounted(() => {
            form.value.loadFromModel(venueServices.value.facilities || venueFacilities.value);
            if (root.$route.params.venueId) {
                loadFacilities().then(() => {
                    loadedForm.value = cloneDeep(form.value);
                    loadedForm.value.onDemandFacilities =
                        loadedForm.value.onDemandFacilities.map(facility => {
                            return {
                                ...facility,
                                inventoryEnabled: facility.maxInventory > 0
                            };
                        });
                });
            }
        });
        return {
            errors,
            form,
            isInformingVenueUpdated,
            isSaving,
            isSettingsChanged,
            loadedForm,
            orderType: SpaceOrderItemType,
            spaceDetails,
            vatRates,
            venueFacilities,
            venueServices,
            cancelInformingVenueUpdated,
            checkErrors,
            getVatError,
            hasChanges,
            isChangesSaved,
            mainAction,
            onRevert,
            onSaveChanges,
            saveChanges,
            startInformingVenueUpdated,
            updateFacilityLimit
        };
    }
});
