var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"venue-facilities page-details"},[_c('h1',[_vm._v(_vm._s(_vm.$t('onboarding.venue.facilities.label_header')))]),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"present-facilities form-input"},[_c('div',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('onboarding.venue.facilities.label_on_facilities_header'))+" ")]),_vm._l((_vm.form.presentFacilities),function(facility,index){return _c('FormServiceItem',{key:index,staticClass:"default-item",attrs:{"item":facility,"locales":'filter.facility'}},[(facility.selected && facility.expectQuantity)?_c('div',{staticClass:"custom-form"},[_vm._v(" "+_vm._s(_vm.$t('space.facilities.default_facilities_limit_management'))+" ")]):_vm._e()])})],2),_c('div',{staticClass:"on-demand-facilities form-input"},[_c('div',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t( 'onboarding.venue.facilities.label_on_demand_facilities_header' ))+" ")]),_c('InfoBlock',[_vm._v(" "+_vm._s(_vm.$t('onboarding.venue.facilities.label_info_on_demand_facilities'))+" ")]),_vm._l((_vm.form.onDemandFacilities),function(facility,index){return _c('FormServiceItem',{key:index,staticClass:"demand-item",attrs:{"item":facility,"locales":'filter.facility'}},[(facility.selected)?_c('div',{staticClass:"custom-form"},[_c('PriceInput',{attrs:{"vat":facility.vat,"service":"","advertised":"","max":999.99},model:{value:(facility.price),callback:function ($$v) {_vm.$set(facility, "price", $$v)},expression:"facility.price"}}),_c('div',{staticClass:"toggle-content"},[(facility.orderType === _vm.orderType.PerItemOrPerHour)?_c('Toggle',{staticClass:"facility-toggle",model:{value:(facility.venueServiceOrderType),callback:function ($$v) {_vm.$set(facility, "venueServiceOrderType", $$v)},expression:"facility.venueServiceOrderType"}},[_c('Option',{attrs:{"label":_vm.$t(
                      'onboarding.venue.facilities.button_label_each'
                    ).toString(),"value":"PER_ITEM"}}),_c('Option',{attrs:{"label":_vm.$t(
                      'onboarding.venue.facilities.button_label_hour'
                    ).toString(),"value":"PER_HOUR"}})],1):_c('div',{staticClass:"order-type-label"},[_vm._v(" "+_vm._s(_vm.$t( ("filter.facility.order_type." + (facility.orderType.toLowerCase())) ).toUpperCase())+" ")]),_c('div',{staticStyle:{"margin":"15px 0 0 15px"}},[_c('Popover',{attrs:{"offset":[135, -50]}},[_c('div',[_c('i',{staticClass:"fa-solid fa-circle-question fa-xs"})]),_c('div',{staticClass:"popover-content",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t( ("inventory_management.modal.order_type." + (facility.orderType.toLowerCase()) + ".description") ))+" ")])])],1)],1),_c('FormSelect',{attrs:{"id":("vatRate-" + (facility.id)),"placeholder":_vm.$t(
                  'onboarding.organisation_details.input_placeholder_vat_rate'
                ).toString(),"options":_vm.vatRates,"error":_vm.getVatError(facility),"inline":"","required":""},model:{value:(facility.vat),callback:function ($$v) {_vm.$set(facility, "vat", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"facility.vat"}}),_c('WMInput',{attrs:{"inline":true,"placeholder":_vm.$t(
                  'onboarding.venue.facilities.input_placeholder_on_demand_facility_description'
                ).toString()},model:{value:(facility.description),callback:function ($$v) {_vm.$set(facility, "description", $$v)},expression:"facility.description"}}),_c('FormVerticalRadio',{attrs:{"form":facility,"limit":'maxInventory',"title":_vm.$t(
                  'onboarding.venue.facilities.label_manage_inventory_title'
                ).toString(),"first-label":_vm.$t(
                  'onboarding.venue.facilities.label_manage_inventory_first'
                ).toString(),"second-label":_vm.$t(
                  'onboarding.venue.facilities.label_manage_inventory_second'
                ).toString(),"has-incremented-input":true},on:{"input":function($event){return _vm.updateFacilityLimit(facility, $event)},"updatedLimit":function (val) { return (facility.maxInventory = val); }},model:{value:(facility.selectedLimitOption),callback:function ($$v) {_vm.$set(facility, "selectedLimitOption", $$v)},expression:"facility.selectedLimitOption"}})],1):_vm._e()])})],2),(!_vm.$route.params.venueId)?_c('WMButton',{attrs:{"data-gtm":"venue_facilities_form_continue_button","icon-class-name":"fa-solid fa-arrow-right fa-lg","straight":""},on:{"click":_vm.mainAction}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('onboarding.venue.facilities.button_label_primary')))])]):_vm._e()],1)]),_c('SaveFooter',{attrs:{"disabled":_vm.isSaving,"on-revert":_vm.onRevert,"on-save":_vm.onSaveChanges,"on-close":_vm.cancelInformingVenueUpdated,"is-changed":_vm.hasChanges,"is-saved":_vm.isChangesSaved,"page":'venue'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }